.card {
  @include card;

  padding: $padding;

  &__background-color {
    &--fill{
      background-color: $fill-color;
    }

    &--light-blue{
      background-color: $blue-light;
    }
  }

  &__horizontal-placement {
    &--left {
      align-items: flex-start;
    }

    &--center {
      align-items: center;
    }

    &--right {
      align-items: flex-end;
    }
  }

  &__height {
    &--full {
      height: 100%;
    }
  }

  &__width {
    &--full {
      width: 100%;
    }
  }
}
